import { React } from "react";
import { Container } from "react-bootstrap";
import InputItem from "./InputItem";
import OutputItem from "./OutputItem";
import StatusItem from "./StatusItem";

function Deploy(props) {

  return (
    <Container>
      <div className="my-5">
        <InputItem
          title={"Describe your dream webpage:"}
          text={props.prompt}
          buttonText={"Submit"}
          onChange={(e) => {
            props.handleInput(e.target.value);
          }}
          onClick={props.handleSubmit}
        />
      </div>
      <div className="my-5">
        <OutputItem
          title={"Results:"}
          text={props.ai_response || props.lastResponse}
          buttonText={["Preview", "Deploy"]}
          onClick={props.handleDeploy}
        />
      </div>
      <div className="my-5">
        <StatusItem
          title={"Deployment Status:"}
          text={props.deployment_status}
        />
      </div>
    </Container>
  );
}

export default Deploy;
