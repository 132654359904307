// Desc: Main App component
import React, { StrictMode, useState, useEffect } from "react";
import Development from "./components/Development";
import Home from "./components/Home";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

export default function App() {
  const [activePage, setActivePage] = useState("home");
  const [lastResponse, setLastResponse] = useState("");
  const [activeContent, setActiveContent] = useState({
    title: "",
    backgroundColor: "",
    color: "",
    quotes: []
  });

  useEffect(() => {
    // Fetch app_settings.json
    fetch("/app_settings.json")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        setActiveContent(data);
      })
      .catch(error => {
        console.error("Error fetching the settings.json file:", error);
      });
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (
    <StrictMode>
      <div className="App">
        {activePage === "home" ? (
          <Home
            selTab="home"
            activeContent={activeContent}
            setActivePage={setActivePage}
            activePage={activePage}
          />
        ) : (
          <Development
            selTab="dashboard"
            setActivePage={setActivePage}
            activePage={activePage}
            setActiveContent={setActiveContent}
            activeContent={activeContent}
            lastResponse={lastResponse}
            setLastResponse={setLastResponse}
          />
        )}
      </div>
    </StrictMode>
  );
}
