// Desc: Main App component
import "bootstrap/dist/css/bootstrap.min.css";
import "../index.css";
import Header from "./Header.js";
import Quote from "./Quote.js";

export default function Home(props) {
  return (
    <div className="App" >
      <Header selTab={props.selTab} activePage={props.activePage} setActivePage={props.setActivePage} activeContent={props.activeContent} />
      <h1 >{props.activeContent.title}</h1>
      <Quote activeContent={props.activeContent} />
    </div>
  );
}
