import '../index.css';
import logo from '../logo.svg';

function getRandomQuote(quotes) {
    const defaultQuotes = [
        "It always seems impossible until it's done. - Nelson Mandela",
        "The greatest glory in living lies not in never falling, but in rising every time we fall. - Nelson Mandela",
        "The only way to do great work is to love what you do. - Steve Jobs",
        "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
        "Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
        "The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
        "Believe you can and you're halfway there. - Theodore Roosevelt",
        "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
        "The future starts today, not tomorrow. - Pope John Paul II",
        "The only way to discover the limits of the possible is to go beyond them into the impossible. - Arthur C. Clarke"
    ];
    if (!quotes || quotes.length === 0) {
        quotes = defaultQuotes;
    }
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
}

export default function Quote({ activeContent }) {
    try {
        const quotes = activeContent.quotes;
        const randomQuote = getRandomQuote(quotes);
        console.log(randomQuote);
        const quote_of_day = randomQuote;
        console.log(`quote_of_day: ${quote_of_day}`);
        return (
            <div className="Quote" style={activeContent}>
                <img src={logo} className="App-logo" alt="logo" />
                <h3>Quote of the day:</h3>
                <p>{quote_of_day}</p>
            </div>
        );

    } catch (error) {
        console.log(`Error ${error.message}`);
        return `Error ${error}`;
    }
};
