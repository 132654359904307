import { Button, FormControl } from "react-bootstrap";
import "./InputItem.css";

export default function InputItem(props) {
  return (
    <div className="input-item">
      <div className="input-item h2"> {props.title} </div>
      <div className="input-item__textbox">
        <FormControl type="text" placeholder={props.text} onChange={props.onChange} />
      </div>
      <div className="input-item__button">
        <Button onClick={props.onClick}>{props.buttonText}</Button>
      </div>
    </div>
  );
}
