// Desc: Main Development component
import { useState, useEffect } from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header.js";
import Deploy from "./Deploy.js";
import fetch from "node-fetch";

// safe way read token from environment variable
const token = process.env.REACT_APP_API_TOKEN;

const lambdaPostPrompt = (prompt) => {
  let goodPrompt = ""
  if (prompt !== "") {
    goodPrompt = prompt;
  }
  // fetch with bearer token
  return fetch(
    "https://4ygjhghgci.execute-api.us-east-1.amazonaws.com/Dev/ai",
    {
      method: "POST",
      body: JSON.stringify({ prompt: goodPrompt }), // body data type must match "Content-Type" header
      headers: {
        "Content-Type": "application/json"
        // "Authorization": "Bearer " + token, // if you're using bearer token, uncomment this line
      },
    }
  )
    .then("Reading response...")
    .then((response) => response.json());
};
const lambdaGetPrompt = () => {
  return fetch(
    "https://4ygjhghgci.execute-api.us-east-1.amazonaws.com/Dev/ai",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + token, // if you're using bearer token, uncomment this line
      },
    }
  )
    .then("Reading response...")
    .then((response) => response.json());
};

const isValidJSON = (json) => {
  try {
    const str = JSON.stringify(json);
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};


const lambdaGetDeploymentStatus = () => {
  return fetch(
    "https://4ygjhghgci.execute-api.us-east-1.amazonaws.com/Dev/deploy"
  )
    .then("Reading response...")
    .then((response) => response.json());
};

const lambdaPostDeploymentStatus = async (lastResponse) => {
  if (lastResponse !== null && isValidJSON(lastResponse)) {
    let body = {};
    body = lastResponse;

    try {
      // fetch with bearer token
      return fetch(
        "https://4ygjhghgci.execute-api.us-east-1.amazonaws.com/Dev/deploy",
        {
          method: "POST",
          body: JSON.stringify(body), // body data type must match "Content-Type" header
          headers: {
            "Content-Type": "application/json"
            // "Authorization": "Bearer " + token, // if you're using bearer token, uncomment this line
          },
        }
      )
        .then("Reading response...")
        .then((response) => response.json());

    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  else {
    return "no valid results";
  }
};


export default function Development(props) {
  const [ai_response, setAIResponse] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [deployment_status, setDeploymentStatus] = useState("Ready to deploy!");
  const [isDeployButtonDisabled, setIsDeployButtonDisabled] = useState(false);
  const lastPrompt = prompt;
  const setActivePage = props.setActivePage;
  const setActiveContent = props.setActiveContent;
  const setLastResponse = props.setLastResponse;

  console.log("LastPrompt: " + lastPrompt);
  console.log("LastResponse: " + props.lastResponse);

  const handleSubmitClick = async (event) => {
    var result = "no valid results";
    try {
      const initRes = "Processing...";
      setAIResponse(initRes);
      console.log("Prompt: " + lastPrompt);
      const cresult = await lambdaPostPrompt(lastPrompt);
      if (cresult === null) {
        lambdaGetPrompt();
      }
      else if (isValidJSON(cresult)) {
        const resultStr = JSON.stringify(cresult);
        if ("title" in cresult && "quotes" in cresult && typeof cresult.quotes[0] === 'string') {
          setAIResponse(resultStr);
          setActiveContent(cresult);
          setIsDeployButtonDisabled(false);
          setDeploymentStatus("Ready to deploy!");
        }
        else if (cresult.statusCode === 200) {
          console.log('No title found:', resultStr);
          setAIResponse(resultStr);
          setIsDeployButtonDisabled(true);
          setDeploymentStatus("Invalid Result. Please try again!");
        }
        else {
          console.log('No title found:', resultStr);
          setAIResponse(resultStr);
          setIsDeployButtonDisabled(true);
          setDeploymentStatus("Invalid Result. Please try again!");
        }
      } else {
        setAIResponse(initRes);
        setIsDeployButtonDisabled(true);
        setDeploymentStatus("Invalid Result. Please try again!");
      }


    } catch (error) {
      console.log(result);
      setAIResponse(result);
    }
  };

  const handleDeployClick = async (event) => {
    // event.preventDefault();
    console.log(event.target.innerText);
    try {
      if (isDeployButtonDisabled) {
        return;
      }
      if (event.target.innerText === "Deploy") {
        const result = await lambdaPostDeploymentStatus(props.lastResponse);
        if (result !== null && isValidJSON(result)) {
          console.log(result);
          setDeploymentStatus(result["body"]);
        }
      } else {
        console.log(ai_response);
        setLastResponse(ai_response);
        setActivePage("home");
      }

    } catch (error) {
      console.error(error);
    }
  };

  const handleInput = (value) => {

    setPrompt(value);
  };

  return (
    <div className="App">
      <Header selTab={props.selTab} activePage={props.activePage} setActivePage={props.setActivePage} />
      <Deploy
        handleInput={handleInput}
        handleSubmit={handleSubmitClick}
        handleDeploy={handleDeployClick}
        setPrompt={setPrompt}
        ai_response={ai_response}
        lastResponse={props.lastResponse}
        prompt={prompt}
        deployment_status={deployment_status}
        title={"Describe your dream webpage:"}
      />
    </div>
  );
}
