import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./../index.css";
import { Button } from "react-bootstrap";

const menuItems = {
  home:
  {
    name: "Home",
    link: "/",
  },
  development:
  {
    name: "Development",
    link: "/development",
  },
  production:
  {
    name: "Production",
    link: "https://thedevopsexperience.co.uk",
  }
};

export default function Header(props) {
  const setActivePage = props.setActivePage;

  const createElements = (selection) => {
    const activeColor = (currentMenuItem) => {
      if (selection.toLowerCase() === currentMenuItem.toLowerCase()) {
        return "black";
      } else {
        return "gray";
      }
    };
    const selectedColorStyle = (current) => {
      return {
        backgroundColor: "lightblue",
        color: activeColor(current),
      };
    };
    const navbarStyle = {
      backgroundColor: "lightblue",
      color: "white",
    };

    return (
      <div >
        <Navbar style={navbarStyle}>
          <Nav className="my-2">
            <div style={selectedColorStyle(menuItems.home.name)}>
              <Button onClick={(e) => { setActivePage("home") }} >
                {menuItems.home.name}
              </Button>
            </div>

            <div style={selectedColorStyle(menuItems.development.name)}>
              <Button onClick={(e) => { setActivePage("development") }}>
                {menuItems.development.name}
              </Button>
            </div>
          </Nav>
        </Navbar >
        <div className="App" style={{
          color: "gray"
        }}>

        </div>
      </div >
    );
  };

  return createElements(props.selTab);
}
