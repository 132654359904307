import { Button } from "react-bootstrap";
import "./OutputItem.css";

export default function OutputItem(props) {
  return (
    <div className="output-item">
      <div className="output-item h2"> {props.title} </div>
      <div className="output-item__textbox">
        {props.text}
      </div>
      <div className="output-item__button">
        <Button onClick={props.onClick}>{props.buttonText[0]}</Button>
      </div>
      <div className="output-item__button">
        <Button onClick={props.onClick}>{props.buttonText[1]}</Button>
      </div>
    </div>
  );
}
