import { FormControl } from "react-bootstrap";
import "./StatusItem.css";

export default function StatusItem(props) {
  return (
    <div className="status-item">
      <div className="status-item h2"> {props.title} </div>
      <div className="status-item">
        <FormControl placeholder={props.text} disabled={true} />
      </div>
    </div>
  );
}
